import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reported',
  templateUrl: './reported.component.html',
  styleUrls: ['./reported.component.scss']
})
export class ReportedComponent implements OnInit {

  selected = 1;

  constructor() { }

  ngOnInit(): void { }

  selectPart(nb: number): void {
    this.selected = nb;
  }

}
