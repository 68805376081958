import {Component, Inject, OnInit} from '@angular/core';
import {User} from '../../../core/models/user';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UsersService} from '../../../core/api/users/users.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-detail-user',
  templateUrl: './detail-user.component.html',
  styleUrls: ['./detail-user.component.scss']
})
export class DetailUserComponent implements OnInit {

  user: User;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DetailUserComponent>,
              private userService: UsersService,
              private snackBar: MatSnackBar) {
    this.user = this.data.user;
  }

  ngOnInit(): void {
  }

  deletingPersonalData(): void {
    const body = {
      delete_personal_data: true,
    };
    this.userService.updateUser(body, this.user.id).subscribe(data => {
      this.snackBar.open('Les informations personnelles ont été supprimées.');
      this.dialogRef.close();
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
