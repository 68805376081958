import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../core/api/users/users.service';
import { MatTableDataSource } from '@angular/material/table';
import { User, UserPaginated } from '../../core/models/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DetailUserComponent } from './detail-user/detail-user.component';
import { DisableUserComponent } from './disable-user/disable-user.component';
import { EnableUserComponent } from './enable-user/enable-user.component';
import { PaginationMetadata } from 'src/app/core/models/pagination';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  displayedColumns: string[] = ['id', 'firstname', 'lastname', 'nickname', 'email', 'is_licensee', 'actions'];
  users: Array<User> = [];
  dataSource = new MatTableDataSource(this.users);

  pagination: PaginationMetadata = {
    count: 0,
    page: 1,
    items: 0,
    pages: 1,
    prev: null,
    next: null
  };

  search: string = '';

  constructor(private usersService: UsersService,
              private snackBar: MatSnackBar,
              private matDialog: MatDialog) {
  }

  ngOnInit(): void {
    this.getUsers();
  }

  /**
   * Get Users either on given page or current page.
   * @param page Page to fetch
   */
  getUsers(page?: number): void {
    this.usersService.getAllUsers({ page: page ?? this.pagination.page, search: this.search })
    .subscribe((data: UserPaginated) => {
      this.pagination = data.page;
      this.users = data.users;
      this.dataSource = new MatTableDataSource(this.users);
    });
  }

  edit(id: number): void {
    const dialogRef = this.matDialog.open(DetailUserComponent, {
      width: '400px',
      data: {
        user: this.users?.find((e) => e.id === id)
      }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getUsers();
    });
  }

  disable(id: number): void {
    const user = this.users.find((e) => e.id === id);
    const name = user?.firstname === null || user?.lastname === null ? 'n°' + user?.id : user?.firstname + ' ' + user?.lastname;
    const dialogRef = this.matDialog.open(DisableUserComponent, {
      width: '600px',
      height: '220px',
      data: {
        username: name
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const body = {
          is_disabled: true,
        };
        this.usersService.updateUser(body, id).subscribe(data => {
          this.snackBar.open('L\'utilisateur a été désactivé.');
          this.getUsers();
        });
      }
    });
  }

  enable(id: number): void {
    const user = this.users.find((e) => e.id === id);
    const name = user?.firstname === null || user?.lastname === null ? 'n°' + user?.id : user?.firstname + ' ' + user?.lastname;
    const dialogRef = this.matDialog.open(EnableUserComponent, {
      width: '600px',
      height: '220px',
      data: {
        username: name
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const body = {
          is_disabled: false,
        };
        this.usersService.updateUser(body, id).subscribe(data => {
          this.snackBar.open('L\'utilisateur a été réactivé.');
          this.getUsers();
        });
      }
    });
  }

  previous(): void {
    this.getUsers(this.pagination.prev);
  }

  next(): void {
    this.getUsers(this.pagination.next);
  }

}
