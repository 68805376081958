<mat-toolbar>
  <div class="header">
    <div class="menu" *ngIf="tokenService.isLoggedIn()">
      <a class="nav-item" routerLink="/configuration" routerLinkActive="active">Configuration</a>
      <a class="nav-item" routerLink="/users" routerLinkActive="active">Utilisateurs</a>
      <a class="nav-item" routerLink="/reported" routerLinkActive="active">Modération des contenus</a>
    </div>
    <a class="logout-button" (click)="logout()" *ngIf="tokenService.isLoggedIn()">Déconnexion</a>
  </div>
</mat-toolbar>
