<mat-dialog-content>
  <div class="col ml-20 mr-20">
    <div class="row header-dlg shadow-bottom">
      <span class="cross ml-20" (click)="closeDialog()">&#10005;</span>
      <span class="auto-flex" style="text-align: center">Membre n°{{user.id}}</span>
    </div>
    <br/>

    <mat-form-field appearance="outline">
      <mat-label>E-mail</mat-label>
      <input matInput readonly class="input-read-only" [value]="user.email" placeholder="E-mail"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Pseudo</mat-label>
      <input matInput readonly class="input-read-only" [value]="user.nickname" placeholder="Pseudo-mail"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Prénom</mat-label>
      <input matInput readonly class="input-read-only" [value]="user.firstname" placeholder="Prénom"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Nom</mat-label>
      <input matInput readonly class="input-read-only" [value]="user.lastname" placeholder="Nom"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Licencié</mat-label>
      <input matInput readonly class="input-read-only" [value]="user.is_licensee ? 'Oui' : 'Non'" placeholder="Licencié"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Civilité</mat-label>
      <input matInput readonly class="input-read-only" [value]="user.civility == 'male' ? 'Homme' : 'Femme'" placeholder="Civilité"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Date de naissance</mat-label>
      <input matInput readonly class="input-read-only" [value]="user.birthdate" placeholder="Date de naissance"/>
    </mat-form-field>

    <button mat-flat-button class="sec-btn mt-10 mb-10" (click)="deletingPersonalData()">
      Effacer les données personnelles
    </button>


  </div>
</mat-dialog-content>
