<mat-dialog-content>
  <div class="col ml-20 mr-20">
    <div class="row header-dlg shadow-bottom">
      <span class="cross ml-20" (click)="closeDialog()">&#10005;</span>
      <span class="auto-flex" style="text-align: center">{{ data.title }}</span>
    </div>
    <br/>

    <mat-form-field appearance="outline">
      <mat-label>Prénom de l'utilisateur</mat-label>
      <input matInput readonly class="input-read-only" [value]="data.user.firstname" placeholder="Prénom"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Nom de l'utilisateur</mat-label>
      <input matInput readonly class="input-read-only" [value]="data.user.lastname" placeholder="Nom"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Discipline</mat-label>
      <input matInput readonly class="input-read-only" [value]="data.criteria.title" placeholder="Nom"/>
    </mat-form-field> 

    <mat-form-field appearance="outline">
      <mat-label>Distance</mat-label>
      <input matInput readonly class="input-read-only" [value]="data.distance" placeholder="Civilité"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Durée</mat-label>
      <input matInput readonly class="input-read-only" [value]="data.practice_time" placeholder="Date de naissance"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Vitesse moyenne</mat-label>
      <input matInput readonly class="input-read-only" [value]="data.speed_amount" placeholder="Civilité"/>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Commentaire</mat-label>
      <input matInput readonly class="input-read-only" [value]="data.description" placeholder="Civilité"/>
    </mat-form-field>

    <div class="photos-container">
      <span>Photos</span>
      <img *ngFor="let photo of data.photos" [src]="photo.url" alt="photo d'expérience">
    </div>
    
  </div>
</mat-dialog-content>
