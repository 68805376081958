import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PaginationService } from '../pagination.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportedService extends PaginationService {

  private reportedURL = '/reported';
  private reportedUsersURL = '/users';
  private reportedExperiencesURL = '/experiences';

  constructor(protected httpClient: HttpClient) { 
    super(httpClient, environment.apiUrl + environment.apiVersion)
  }

  getUsersReported(params?: any): Observable<any> {
    return this.getAllPaginated(this.reportedURL + this.reportedUsersURL, params);
  }

  getExperiencesReported(params?: any): Observable<any> {
    return this.getAllPaginated(this.reportedURL + this.reportedExperiencesURL, params);
  }

  updateExperiencesReported(params: any, id: number): Observable<any> {
    return this.httpClient.put(environment.apiUrl + environment.apiVersion + this.reportedURL + this.reportedExperiencesURL + '/' + id, params)
    .pipe(
      take(1),
    );
  }

}
