import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from '../../../core/api/configuration/configuration.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {PracticeEditComponent} from './practice-edit/practice-edit.component';
import {PracticeDeleteComponent} from './practice-delete/practice-delete.component';

@Component({
  selector: 'app-practice',
  templateUrl: './practice.component.html',
  styleUrls: ['./practice.component.scss']
})
export class PracticeComponent implements OnInit {

  displayedColumns: string[] = ['id', 'title', 'actions'];
  criteriaPractice: Array<any> = [];
  dataSource = new MatTableDataSource(this.criteriaPractice);
  criteriaCreate: any = {
    title: ''
  };
  pagination: any;

  constructor(private configurationService: ConfigurationService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getCriteriaPractice(1);
  }

  getCriteriaPractice(pagination: any): void {
    this.configurationService.getCriteriaPractice({page: pagination}).subscribe(data => {
      this.pagination = data.page;
      this.criteriaPractice = data.criterias.criterias;
      this.dataSource = new MatTableDataSource(this.criteriaPractice);
    });
  }

  add(): void {
    const ref = this.dialog.open(PracticeEditComponent, {
      width: '400px',
      height: '400px',
      data: {criteria: this.criteriaCreate, isCreate: true}
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.getCriteriaPractice(1);
      }
    });
  }

  edit(elem: any): void {
    const ref = this.dialog.open(PracticeEditComponent, {
      width: '400px',
      height: '400px',
      data: {criteria: elem, isCreate: false}
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.getCriteriaPractice(1);
      }
    });
  }

  delete(elem: any): void {
    const dialogRef = this.dialog.open(PracticeDeleteComponent, {
      width: '600px',
      height: '220px',
      data: elem
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.configurationService.deleteCriteriaPractice(elem.id).subscribe(data => {
          this.getCriteriaPractice(1);
          this.snackBar.open('La discipline a été supprimée.');
        });
      }
    });
  }

  previous(): void {
    this.getCriteriaPractice(this.pagination.prev);
  }

  next(): void {
    this.getCriteriaPractice(this.pagination.next);
  }

}
