<div class="modal">
  <h2 mat-dialog-title>Désactiver {{data.title}}</h2>
  <mat-dialog-content class="mat-typography">
    <p>Etes-vous sur de vouloir désactiver l'expérience {{data.title}} ?</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close class="sec-btn mt-10 mb-10">Annuler</button>
    <button mat-flat-button [mat-dialog-close]="true" class="prim-btn">Valider</button>
  </mat-dialog-actions>
</div>
