import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthenticationComponent} from './feature/authentication/authentication.component';
import {AuthGuard} from './core/guards/auth.guard.service';
import {ConfigurationComponent} from './feature/configuration/configuration.component';
import {UsersComponent} from './feature/users/users.component';
import {ReportedComponent} from './feature/reported/reported.component';
import {SourcesComponent} from './feature/sources/sources.component';
import {SharedComponent} from './feature/shared/shared.component';

const routes: Routes = [
  { 
    path: '', 
    pathMatch: 'full', 
    redirectTo: 'login' 
  },
  { 
    path: 'shared', 
    component: SharedComponent  
  },
  { 
    path: 'login', 
    component: AuthenticationComponent 
  },
  { 
    path: 'configuration', 
    canActivate: [AuthGuard], 
    component: ConfigurationComponent 
  },
  { 
    path: 'users', 
    canActivate: [AuthGuard], 
    component: UsersComponent 
  },
  { 
    path: 'reported', 
    canActivate: [AuthGuard], 
    component: ReportedComponent 
  },
  { 
    path: 'sources', 
    canActivate: [AuthGuard], 
    component: SourcesComponent 
  },
  { 
    path: '**', 
    pathMatch: 'full', 
    redirectTo: '/login' 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
