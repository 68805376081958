import {environment} from '../../environments/environment';
import CryptoJS from 'crypto-js';

export function getApiAccesToken(request: any, timestamp: string): string {
  const path = getPathFromUrl(request.urlWithParams);
  const body = request.body ? request.body : '';
  const byteSize = str => new Blob([str]).size;
  const bodylength = request.body ? byteSize(JSON.stringify(body)) : 0;
  let cryptedbody;
  if (bodylength === 0) {
    cryptedbody = CryptoJS.MD5(body).toString();
  } else {
    cryptedbody = CryptoJS.MD5(JSON.stringify(body)).toString();
  }
  const token = path + request.method + bodylength + cryptedbody + timestamp;
  const encryptedToken = CryptoJS.HmacSHA512(token, environment.api_key).toString();
  return encryptedToken;
}


export function getPathFromUrl(url: string): string {
  const urlSplited = url.split('/');
  let indexOfPathBegin = 0;
  urlSplited.forEach((item, index) => {
    if (item.includes('admin')) {
      indexOfPathBegin = index;
    }
  });
  let path = '';
  for (let i = indexOfPathBegin; i < urlSplited.length; i++) {
    path += '/' + urlSplited[i];
  }
  return path;
}

export function saveToken(token: any): void {
  sessionStorage.setItem('token', JSON.stringify(token.token));
  sessionStorage.setItem('expiresAt', JSON.stringify(token.expiredAt));
}

export function getTokenExpiration(): string {
  let tokenExpiration = sessionStorage.getItem('expiresAt');
  if (tokenExpiration != null) {
    tokenExpiration = JSON.parse(tokenExpiration);
  }
  return tokenExpiration ? tokenExpiration : '';
}

export function getToken(): string {
  let token = sessionStorage.getItem('token');
  if (token != null) token = JSON.parse(token);
  return token ? token : '';
}

export function logout(): void {
  sessionStorage.clear();
}
