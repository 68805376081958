import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TokenService} from '../../core/api/token/token.service';
import {saveToken} from '../../shared/utils';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

  email = '';
  password = '';
  error = '';

  private returnUrl: string;

  constructor(private tokenService: TokenService, private router: Router, private route: ActivatedRoute) {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/users';
    if (this.tokenService.isLoggedIn()) {
      this.router.navigate([this.returnUrl === '/login' ? '/users' : this.returnUrl]);
    } else {
      this.tokenService.loginData.subscribe((data: { email: string, password: string, rememberMe: string }) => {
        if (data) {
          this.email = data.email;
          this.password = data.password;
          this.submit();
        }
      }, error => console.error(error));
    }
  }

  ngOnInit(): void {
  }

  submit(): void {
    const params = {
      email: this.email,
      password: this.password
    };
    this.tokenService.authentication(params).subscribe((data: any) => {
      saveToken(data);
      this.router.navigate([this.returnUrl === '/login' ? '/users' : this.returnUrl]);
    }, error => {
      this.error = 'Veuillez vérifier vos informations de connexion';
    });
  }

}
