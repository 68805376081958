<div class="container">

  <mat-form-field appearance="outline">
    <mat-label>Rechercher un utilisateur par nom, prénom ou adresse email</mat-label>
    <input matInput [(ngModel)]="search" placeholder="Nom..." (ngModelChange)="getUsers()"/>
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="firstname">
      <th mat-header-cell *matHeaderCellDef>Prénom</th>
      <td mat-cell *matCellDef="let element"> {{element.firstname}} </td>
    </ng-container>

    <ng-container matColumnDef="lastname">
      <th mat-header-cell *matHeaderCellDef>Nom</th>
      <td mat-cell *matCellDef="let element"> {{element.lastname}} </td>
    </ng-container>

    <ng-container matColumnDef="nickname">
      <th mat-header-cell *matHeaderCellDef>Pseudo</th>
      <td mat-cell *matCellDef="let element"> {{element.nickname}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="is_licensee">
      <th mat-header-cell *matHeaderCellDef>Licencié</th>
      <td mat-cell *matCellDef="let element"> {{element.is_licensee ? 'Oui' : 'Non'}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <a class="icon" (click)="edit(element.id)"><img src="/assets/edit.svg"></a>
        <a class="icon" (click)="disable(element.id)" *ngIf="!element.is_disabled"><img src="/assets/moins.svg"></a>
        <a class="icon" (click)="enable(element.id)" *ngIf="element.is_disabled"><img src="/assets/plus.svg"></a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="users.length > 0" class="row mt-30" style="justify-content: center;">
    <div [class]="pagination?.prev != null ? 'button-pagination' : 'button-pagination disabled'" (click)="previous()"><span>Précédent</span></div>
    <div class="ml-20" [class]="pagination?.next != null ? 'button-pagination' : 'button-pagination disabled'" (click)="next()"><span>Suivant</span></div>
  </div>
</div>
