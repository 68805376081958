import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Experience } from 'src/app/core/models/experience';

@Component({
  selector: 'app-detail-experience',
  templateUrl: './detail-experience.component.html',
  styleUrls: ['./detail-experience.component.scss']
})
export class DetailExperienceComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Experience,
    public dialogRef: MatDialogRef<DetailExperienceComponent>,
  ) { }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
