import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfigurationService} from '../../../../core/api/configuration/configuration.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-parameter-edit',
  templateUrl: './parameter-edit.component.html',
  styleUrls: ['./parameter-edit.component.scss']
})
export class ParameterEditComponent implements OnInit {

  errors = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: { parameter: any },
              private dialogRef: MatDialogRef<ParameterEditComponent>,
              private configurationService: ConfigurationService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void { }

  closeDialog(): void {
    this.dialogRef.close();
  }

  checkErrors(): void {
    this.errors = '';
    if (this.data.parameter.value === '') {
      this.errors = 'Tous les champs sont requis !';
    }
  }

  submit(): void {
    this.configurationService.updateConfiguration(this.data.parameter.id, this.data.parameter).subscribe(data => {
      this.snackBar.open('Le paramètre a été modifié.');
      this.dialogRef.close(true);
    }, (error) => {
      if (error.status === 422) {
        this.errors = 'Tous les champs sont requis !';
      } else {
        this.errors = 'Une erreur est survenue.';
      }
    });
  }

  onError(): boolean {
    return this.errors !== '';
  }

}
