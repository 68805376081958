import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-enable-experience',
  templateUrl: './enable-experience.component.html',
  styleUrls: ['./enable-experience.component.scss']
})
export class EnableExperienceComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
