<div class="container">
  <div style="text-align: center;">
    <button mat-flat-button [ngClass]="{'select-part': true, 'selected-part': selected === 1}" (click)="selectPart(1)">Milieux de pratique</button>
    <button mat-flat-button [ngClass]="{'select-part': true, 'selected-part': selected === 2}" (click)="selectPart(2)">Disciplines</button>
    <button mat-flat-button [ngClass]="{'select-part': true, 'selected-part': selected === 3}" (click)="selectPart(3)">Paramètres</button>
  </div>
  <app-location *ngIf="selected === 1"></app-location>
  <app-practice *ngIf="selected === 2"></app-practice>
  <app-parameters *ngIf="selected === 3"></app-parameters>
</div>
