import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.css']
})
export class SharedComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    const userAgent = window.navigator.userAgent;
    const isAndroid = /Android|Windows/.test(userAgent);
    const isIOS = /iPad|iPhone|iPod|Macintosh/.test(userAgent);
    const page = this.transformQueryValueToString(this.route.snapshot.queryParamMap.get('page'));

    let url: string;

    if (isAndroid) {
      url = "market://details?id=com.ffck.pratiquant";
    } else if (isIOS) {
      url = "itms-apps://itunes.apple.com/app/id1569162187";
    } else {
     
      url = '/login';
    }

    if (page) {
      url += `?link=${encodeURIComponent(page)}`;
    }

    window.location.href = url;
  }

  transformQueryValueToString(value: string | null): string {
    if (value) {
      return value;
    }
    return '';
  }
}
