import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {ReportedService} from './api/reported/reported.service';
import {HeaderInterceptor} from './header/header.interceptor';
import {NgModule} from '@angular/core';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    ReportedService,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }
  ]
})
export class ApiModule {
}
