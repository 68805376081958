import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PaginationService } from '../pagination.service';
import { UserPaginated } from '../../models/user';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends PaginationService {

  private usersURL = '/users';

  constructor(protected httpClient: HttpClient) { 
    super(httpClient, environment.apiUrl + environment.apiVersion);
  }

  getAllUsers(params?: any): Observable<UserPaginated> {
    return this.getAllPaginated<UserPaginated>(this.usersURL, params);
  }

  updateUser(params: any, id: number): Observable<any> {
    return this._http.put(this._base + this.usersURL + '/' + id, params)
    .pipe(
      take(1),
    );
  }

}
