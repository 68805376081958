import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ConfigurationService} from '../../../core/api/configuration/configuration.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {LocationEditComponent} from '../location/location-edit/location-edit.component';
import {ParameterEditComponent} from './parameter-edit/parameter-edit.component';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameter.component.html',
  styleUrls: ['./parameter.component.scss']
})
export class ParameterComponent implements OnInit {

  displayedColumns: string[] = ['id', 'label', 'value', 'actions'];
  parameters: Array<any> = [];
  dataSource = new MatTableDataSource(this.parameters);
  pagination: any;

  constructor(private configurationService: ConfigurationService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getConfigurations(1);
  }

  getConfigurations(pagination: any): void {
    this.configurationService.getConfigurations({page: pagination}).subscribe(data => {
      this.pagination = data.page;
      this.parameters = data.configurations;
      this.dataSource = new MatTableDataSource(this.parameters);
    });
  }

  edit(elem: any): void {
    const ref = this.dialog.open(ParameterEditComponent, {
      width: '400px',
      height: '400px',
      data: {parameter: elem}
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.getConfigurations(1);
      }
    });
  }

  previous(): void {
    this.getConfigurations(this.pagination.prev);
  }

  next(): void {
    this.getConfigurations(this.pagination.next);
  }

}
