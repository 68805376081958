import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { catchError, map } from 'rxjs/operators';
import { getTokenExpiration } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { BoUserService } from '../bo-user/bo-user.service';
import {BoUser} from '../../models/bo-user';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private authenticate = '/users/authenticate';
  private loginSubject = new Subject<{email: string, password: string}>();
  loginData = this.loginSubject.asObservable();

  constructor(private httpClient: HttpClient,
              private boUserService: BoUserService
              ) { }

  authentication(params: {email: string, password: string}): Observable<any> {
    return this.httpClient
      .post(environment.apiUrl + environment.apiVersion + this.authenticate, params)
      .pipe(map(data => data as any), catchError((err: any) => err.code === 404 ? [] : throwError(err)));
  }

  public loadUser(): void {
    if (this.isLoggedIn()) {
      this.boUserService.get().subscribe((boUser: BoUser) => {
        this.boUserService.setUser(boUser);
      });
    }
  }

  public isLoggedIn(): boolean {
    return moment().isBefore(this.getExpiration());
  }

  getExpiration(): any {
    return moment(getTokenExpiration());
  }

}
