<div class="col ml-20 mr-20">
  <div class="row header-dlg shadow-bottom" style="margin-bottom: 20px;">
    <span class="cross ml-20" (click)="closeDialog()">&#10005;</span>
    <span class="auto-flex" style="text-align: center">
      {{data.isCreate ? 'Ajouter' : 'Modifier ' + title}}
    </span>
  </div>

  <mat-form-field appearance="outline">
    <mat-label>Titre</mat-label>
    <input matInput [(ngModel)]="data.criteria.title" placeholder="Titre" (ngModelChange)="checkErrors()"/>
  </mat-form-field>

  <mat-error *ngIf="onError()">
    {{errors}}
  </mat-error>

  <button mat-flat-button class="prim-btn mt-20" (click)="submit()" [disabled]="onError()">
    Valider
  </button>

  <button mat-flat-button class="sec-btn mt-10 mb-10" (click)="closeDialog()">
    Annuler
  </button>

</div>
