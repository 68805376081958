import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfigurationService} from '../../../../core/api/configuration/configuration.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-location-edit',
  templateUrl: './location-edit.component.html',
  styleUrls: ['./location-edit.component.scss']
})
export class LocationEditComponent implements OnInit {

  title = '';
  errors = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: { criteria: any, isCreate: boolean },
              private dialogRef: MatDialogRef<LocationEditComponent>,
              private configurationService: ConfigurationService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.title = this.data.criteria.title;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  checkErrors(): void {
    this.errors = '';
    if (this.data.criteria.name === '') {
      this.errors = 'Tous les champs sont requis !';
    }
  }

  submit(): void {
    if (this.data.isCreate) {
      this.configurationService.createCriteriaLocation(this.data.criteria).subscribe(data => {
        this.snackBar.open('Le milieu de pratique a été ajouté.');
        this.dialogRef.close(true);
      }, (error) => {
        if (error.status === 422) {
          this.errors = 'Tous les champs sont requis !';
        } else {
          this.errors = 'Une erreur est survenue.';
        }
      });
    } else {
      this.configurationService.updateCriteriaLocation(this.data.criteria.id, this.data.criteria).subscribe(data => {
        this.snackBar.open('Le milieu de pratique a été modifié.');
        this.dialogRef.close(true);
      }, (error) => {
        if (error.status === 422) {
          this.errors = 'Tous les champs sont requis !';
        } else {
          this.errors = 'Une erreur est survenue.';
        }
      });
    }
  }

  onError(): boolean {
    return this.errors !== '';
  }

}
