import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

  selected = 1;

  constructor() { }

  ngOnInit(): void { }

  selectPart(nb: number): void {
    this.selected = nb;
  }

}
