import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { catchError, map, take } from 'rxjs/operators';
import { PaginationService } from '../pagination.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService extends PaginationService {

  private allConfigurationsURL = '/configurations';
  private configurationURL = '/configuration';
  private criteriaLocationURL = '/locations';
  private criteriaPracticeURL = '/practices';

  constructor(protected httpClient: HttpClient) { 
    super(httpClient, environment.apiUrl + environment.apiVersion)
  }

  getCriteriaLocation(params?: any): Observable<any> {
    return this.getAllPaginated(this.configurationURL + this.criteriaLocationURL, params);
  }

  deleteCriteriaLocation(id: number): Observable<any> {
    return this.httpClient
      .delete(environment.apiUrl + environment.apiVersion + this.configurationURL + this.criteriaLocationURL + '/' + id)
      .pipe(
        take(1),
      );;
  }

  createCriteriaLocation(params: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrl + environment.apiVersion + this.configurationURL + this.criteriaLocationURL, params)
      .pipe(
        take(1),
        map((data) => data as any), 
        catchError((err: any) => err.code === 404 ? [] : throwError(err))
      );
  }

  updateCriteriaLocation(id: number, params: any): Observable<any> {
    return this.httpClient
      .put(environment.apiUrl + environment.apiVersion + this.configurationURL + this.criteriaLocationURL + '/' + id, params)
      .pipe(
        take(1),
        map((data) => data as any), 
        catchError((err: any) => err.code === 404 ? [] : throwError(err))
      );
  }

  getCriteriaPractice(params?: any): Observable<any> {
    return this.getAllPaginated(this.configurationURL + this.criteriaPracticeURL, params);
  }

  deleteCriteriaPractice(id: number): Observable<any> {
    return this.httpClient
      .delete(environment.apiUrl + environment.apiVersion + this.configurationURL + this.criteriaPracticeURL + '/' + id);
  }

  createCriteriaPractice(params: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrl + environment.apiVersion + this.configurationURL + this.criteriaPracticeURL, params)
      .pipe(
        take(1),
        map((data) => data as any), 
        catchError((err: any) => err.code === 404 ? [] : throwError(err))
      );
  }

  updateCriteriaPractice(id: number, params: any): Observable<any> {
    return this.httpClient
      .put(environment.apiUrl + environment.apiVersion + this.configurationURL + this.criteriaPracticeURL + '/' + id, params)
      .pipe(
        take(1),
        map((data) => data as any), 
        catchError((err: any) => err.code === 404 ? [] : throwError(err))
      );
  }

  getConfigurations(params?: any): Observable<any> {
    return this.getAllPaginated(this.allConfigurationsURL, params);
  }

  updateConfiguration(id: number, params: any): Observable<any> {
    return this.httpClient
      .put(environment.apiUrl + environment.apiVersion + this.allConfigurationsURL + '/' + id, params)
      .pipe(
        take(1),
        map((data) => data as any), 
        catchError((err: any) => err.code === 404 ? [] : throwError(err))
      );
  }
}
