import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {getApiAccesToken, getToken} from '../../shared/utils';
import * as moment from 'moment';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const timestampsRequest = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
    if ((request.headers.get('Authorization') &&
      (request.headers.get('Authorization')!.includes('Basic')) ||
      request.headers.get('Content-Type') &&
      request.headers.get('Content-Type') === 'application/x-www-form-urlencoded'))
      return next.handle(request);
    request = request.clone({
      headers: request.headers
        .set('Authorization', getToken())
        .set('x-api-access-token', getApiAccesToken(request, timestampsRequest))
        .set('x-api-timestamp', timestampsRequest)
    });
    return next.handle(request);
  }
}
