import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ReportedService} from '../../../core/api/reported/reported.service';
import {DisableUserComponent} from '../../users/disable-user/disable-user.component';
import {EnableUserComponent} from '../../users/enable-user/enable-user.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {UsersService} from '../../../core/api/users/users.service';

@Component({
  selector: 'app-reported-user',
  templateUrl: './reported-user.component.html',
  styleUrls: ['./reported-user.component.scss']
})
export class ReportedUserComponent implements OnInit {

  displayedColumns: string[] = ['id', 'firstname', 'lastname', 'email', 'nickname', 'actions'];
  users: Array<any> = [];
  dataSource = new MatTableDataSource(this.users);
  pagination: any;

  constructor(private reportedService: ReportedService,
              private usersService: UsersService,
              private snackBar: MatSnackBar,
              private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.getUsers(1);
  }

  getUsers(pagination: any): void {
    this.reportedService.getUsersReported({page: pagination}).subscribe(dataUsers => {
      this.pagination = dataUsers.page;
      this.users = dataUsers.users;
      this.dataSource = new MatTableDataSource(this.users);
    });
  }

  disable(id: number): void {
    const user = this.users.find((e) => e.id === id);
    const name = user?.firstname === null || user?.lastname === null ? 'n°' + user?.id : user?.firstname + ' ' + user?.lastname;
    const dialogRef = this.matDialog.open(DisableUserComponent, {
      width: '600px',
      height: '220px',
      data: {
        username: name
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const body = {
          is_disabled: true,
        };
        this.usersService.updateUser(body, id).subscribe(data => {
          this.snackBar.open('L\'utilisateur a été désactivé.');
          this.getUsers(1);
        });
      }
    });
  }

  enable(id: number): void {
    const user = this.users.find((e) => e.id === id);
    const name = user?.firstname === null || user?.lastname === null ? 'n°' + user?.id : user?.firstname + ' ' + user?.lastname;
    const dialogRef = this.matDialog.open(EnableUserComponent, {
      width: '600px',
      height: '220px',
      data: {
        username: name
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const body = {
          is_disabled: false,
        };
        this.usersService.updateUser(body, id).subscribe(data => {
          this.snackBar.open('L\'utilisateur a été réactivé.');
          this.getUsers(1);
        });
      }
    });
  }

  previous(): void {
    this.getUsers(this.pagination.prev);
  }

  next(): void {
    this.getUsers(this.pagination.next);
  }

}
