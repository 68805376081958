import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BoUserService } from '../api/bo-user/bo-user.service';
import { TokenService } from '../api/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private token: TokenService,
              private router: Router,
              private boUserService: BoUserService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable<boolean>((observer) => {
      if (this.token.isLoggedIn() === false) {
        window.location.href = '/login';
        observer.next(false);
        observer.complete();
      } else {
        this.boUserService.userData.subscribe((boUser: any) => {
          if (boUser?.email === '') {
            this.boUserService.get().subscribe((boUser2: any) => {
              this.boUserService.setUser(boUser2);
              observer.next(true);
              observer.complete();
            });
          }
          observer.next(true);
          observer.complete();
        });
      }
    });
  }

}
