import { Component, OnInit } from '@angular/core';
import {ConfigurationService} from '../../../core/api/configuration/configuration.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {LocationEditComponent} from './location-edit/location-edit.component';
import {LocationDeleteComponent} from './location-delete/location-delete.component';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  displayedColumns: string[] = ['id', 'title', 'actions'];
  criteriaLocation: Array<any> = [];
  dataSource = new MatTableDataSource(this.criteriaLocation);
  criteriaCreate: any = {
    title: ''
  };
  pagination: any;

  constructor(private configurationService: ConfigurationService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getCriteriaLocation(1);
  }

  getCriteriaLocation(pagination: any): void {
    this.configurationService.getCriteriaLocation({page: pagination}).subscribe(data => {
      this.pagination = data.page;
      this.criteriaLocation = data.criterias.criterias;
      this.dataSource = new MatTableDataSource(this.criteriaLocation);
    });
  }

  add(): void {
    const ref = this.dialog.open(LocationEditComponent, {
      width: '400px',
      height: '400px',
      data: {criteria: this.criteriaCreate, isCreate: true}
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.getCriteriaLocation(1);
      }
    });
  }

  edit(elem: any): void {
    const ref = this.dialog.open(LocationEditComponent, {
      width: '400px',
      height: '400px',
      data: {criteria: elem, isCreate: false}
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.getCriteriaLocation(1);
      }
    });
  }

  delete(elem: any): void {
    const dialogRef = this.dialog.open(LocationDeleteComponent, {
      width: '600px',
      height: '220px',
      data: elem
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.configurationService.deleteCriteriaLocation(elem.id).subscribe(data => {
          this.getCriteriaLocation(1);
          this.snackBar.open('Le milieu de pratique a été supprimé.');
        });
      }
    });
  }

  previous(): void {
    this.getCriteriaLocation(this.pagination.prev);
  }

  next(): void {
    this.getCriteriaLocation(this.pagination.next);
  }

}
