import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export abstract class PaginationService {

  constructor(
    protected _http: HttpClient,
    protected _base: string
  ) {}

  /**
   * Http GET call using pagination params.
   */
  getAllPaginated<T>(uri: string, params?: any): Observable<T> {
    params = { ...params, per_page: environment.perPage };
    let encodedParams = this.encodeURLParameters(params);

    return this._http.get<T>(this._base + uri, { params: encodedParams })
    .pipe(
      take(1),
      catchError((err: any) => err.code === 404 ? [] : throwError(err))
    );
  }

  /**
   * Create a new object containing URL encoded parameters.
   */
  private encodeURLParameters(params: any) {
    let encodedParams = {};
    for (const [key, value] of Object.entries(params)) {
      encodedParams[key] = encodeURIComponent(`${value}`);
    }

    return encodedParams;
  }

}
